<template>
  <div>
    <page-component
      pageTitle="Unlinked Supplier Items"
      :headers="headers"
      :items="supplierItems"
    >
      <template v-slot:[`item.supplier`]="{ item }">
        {{ $store.getters.getSupplierName(item.supplier) }}
      </template>
      <template v-slot:[`item.unitCost`]="{ item }">
        ${{
          item.costMoney.amount.toFixed(4) +
          " " +
          item.costMoney.currency +
          " / " +
          $store.getters.getUnitName(item.supplierUnit)
        }}
      </template>
      <template v-slot:[`item.action1`]="props">
        <v-btn
          outlined
          small
          :color="$vuetify.theme.dark ? `` : `primary`"
          @click="
            $router.push({ params: { form: 'link-existing', id: props.item.id } })
          "
        >
          Link to existing item
        </v-btn>
      </template>
      <template v-slot:[`item.action2`]="props">
        <v-btn
          outlined
          small
          :color="$vuetify.theme.dark ? `` : `primary`"
          @click="$router.push({ params: { form: 'link-new', id: props.item.id } })"
        >
          Link to new Item
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="props">
        <v-btn outlined small color="error" @click="deleteItem(props.item)">
          <v-icon class="mt-n1 mr-1">mdi-trash-can-outline</v-icon>
          Delete item
        </v-btn>
      </template>
    </page-component>
    <existing-form
      v-if="supplierItemToLink"
      :visible="$route.params.form == 'link-existing'"
      :supplierItemToLink="supplierItemToLink"
      @close="closeForm"
    />
    <new-form
      v-if="supplierItemToLink"
      :visible="$route.params.form == 'link-new'"
      :supplierItemToLink="supplierItemToLink"
      @close="closeForm"
    />
    <delete-dialog
      :show="showDeleteDialog"
      itemType="SUPPLIER_ITEM"
      :itemToDelete="itemToDelete"
      @close="showDeleteDialog = !showDeleteDialog"
    />
  </div>
</template>

<script>
import PageComponent from "../../shared/PageComponent.vue"
import ExistingForm from "./ExistingForm.vue"
import NewForm from "./NewForm.vue"
import DeleteDialog from "../../shared/dialogs/DeleteDialog.vue"

export default {
  name: "unlinked-inventory-items",
  components: { DeleteDialog, ExistingForm, NewForm, PageComponent },
  props: {
    supplierItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "SKU", value: "supplierSKU" },
        { text: "Name", value: "name" },
        { text: "Supplier", value: "supplier" },
        { text: "Unit Cost", value: "unitCost" },
        { text: "Case Size", value: "caseSize" },
        { text: "Actions", value: "actions" },
        { text: "", value: "action1", sortable: false, width: 100 },
        { text: "", value: "action2", sortable: false, width: 100 },
        { text: "", value: "delete", sortable: false, width: 100 },
      ],
      showDeleteDialog: false,
      itemToDelete: {},
    }
  },
  computed: {
    supplierItemToLink() {
      return this.supplierItems.find(item => item.id == this.$route.params.id)
    },
  },
  methods: {
    deleteItem(item) {
      this.itemToDelete = item
      this.showDeleteDialog = true
    },
    closeForm() {
      this.$router.push({ params: { form: null, id: null } })
      this.$emit("linked")
    },
  },
}
</script>
