<template>
  <form-dialog
    :visible="visible"
    :editMode="false"
    title="Bulk Edit"
    :closeDisabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    @close="$emit('close')"
    @submit="submitHandle"
  >
    <v-row justify="center">
      <v-col cols="3">
        <v-select
          v-model="storage"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="storages"
          item-text="name"
          item-value="id"
          outlined
          label="Storage"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="category"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="categories"
          outlined
          label="Category"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="subcategory"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="subcategories"
          outlined
          label="Subcategory"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="supplier"
          :menu-props="{ bottom: true, offsetY: true }"
          :items="suppliers"
          item-text="name"
          outlined
          label="Supplier"
        />
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col cols="12">
        <v-textarea v-model="comment" label="Comment:" outlined />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import db from "../../../firebaseConfig"
import { mapState } from "vuex"
import { doc, writeBatch } from "firebase/firestore"
import FormDialog from "../../shared/dialogs/FormDialog.vue"

const batch = writeBatch(db)
export default {
  components: {
    FormDialog,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      storage: null,
      restaurantID: null,
      category: null,
      subcategory: null,
      supplier: null,
      updates: {},
      comment: "",
      closeDisabled: false,
      awaitingResponse: false,
    }
  },
  computed: {
    ...mapState([
      "restaurants",
      "categories",
      "subcategories",
      "storages",
      "suppliers",
      "firebaseRefs",
    ]),
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close")
        }
      },
    },
  },
  methods: {
    updateDB() {
      for (let i = 0; i < this.selectedItems.length; i++) {
        let docRef = doc(this.firebaseRefs.itemsRef, this.selectedItems[i].itemID)
        batch.update(docRef, this.updates)
      }
    },
    prepUpdates() {
      if (this.storage != null) {
        this.updates.storage = this.storage
      }
      if (this.category != null) {
        this.updates.category = this.category
      }
      if (this.subcategory != null) {
        this.updates.subcategory = this.subcategory
      }
      if (this.supplier != null) {
        this.updates.supplier = this.supplier
      }
    },
    clearFields() {
      this.storage =
        this.restaurantID =
        this.category =
        this.subcategory =
        this.supplier =
          null
      this.awaitingResponse = this.closeDisabled = false
      this.updates = {}
    },
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      this.prepUpdates()
      this.updateDB()
      await batch.commit()
      this.clearFields()
      this.$emit("editDone")
    },
  },
}
</script>
