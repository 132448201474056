<template>
  <div>
    <fieldset class="pa-2">
      <legend class="px-2">{{ title }}</legend>
      <v-row
        v-for="(ingredient, i) in recipeIngredients"
        :key="i"
        :class="{ 'mt-0': i > 0 }"
      >
        <v-col cols="5">
          <v-autocomplete
            v-model="ingredient.itemID"
            :items="items"
            item-text="name"
            item-value="itemID"
            outlined
            placeholder="Choose item"
            hide-details="auto"
            @input="handleChange"
          />
        </v-col>
        <v-col cols="4">
          <measurement-field
            v-model="ingredient.measurement"
            :item="ingredient"
            allowUnitSelection
            @input="handleChange"
          />
        </v-col>
        <v-col cols="1">
          <v-btn class="mt-3" @click="removeIngredient(i)">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            v-show="recipeIngredients.length == i + 1"
            class="mt-3"
            @click="addIngredient"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="false" cols="9">
          <v-textarea
            v-model="ingredient.comment"
            outlined
            placeholder="Comment"
            :disabled="ingredient.unit == ''"
            rows="3"
            hide-details
            @input="handleChange"
          />
        </v-col>
      </v-row>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex"
import MeasurementField from "./MeasurementField.vue"
import topLevelMixins from "../../mixins.js"
export default {
  name: "ingredient-form",
  components: { MeasurementField },
  mixins: [topLevelMixins],
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: "Ingredients",
    },
  },
  data() {
    return {
      recipeIngredients:
        this.value.length > 0
          ? this.value
          : [
              {
                itemID: "",
                measurement: this.createEmptyMeasurementObject(),
                comment: "",
              },
            ],
    }
  },
  computed: {
    ...mapState(["units", "items"]),
  },
  beforeDestroy() {
    this.recipeIngredients = []
  },
  methods: {
    addIngredient() {
      this.recipeIngredients.push({
        itemID: "",
        measurement: this.createEmptyMeasurementObject(),
        comment: "",
      })
      this.handleChange()
    },
    removeIngredient(index) {
      this.recipeIngredients.splice(index, 1)
      this.handleChange()
    },
    handleChange() {
      this.$emit("input", this.recipeIngredients)
    },
  },
}
</script>
