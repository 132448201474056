<template>
  <v-list-item>
    <v-row>
      <v-col cols="3">
        <v-text-field
          id="field"
          v-model="unitName"
          label="Add New"
          placeholder="unit name"
        />
      </v-col>
      <v-col cols="1">
        <span class="mx-5">=</span>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="baseAmount"
          label="Equivalent to"
          placeholder="amount"
          :rules="[rules.required, rules.name]"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="baseUnit"
          :items="baseUnitOptions"
          label="Base unit"
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>
    <v-icon @click="submitHandle()">mdi-plus</v-icon>
  </v-list-item>
</template>

<script>
import store from "@/store"
export default {
  props: {
    restaurantID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: v => !!v || "Value is required",
        name: v => !"" || isNaN(v) || "Name value must be alphabetical",
      },
      baseUnit: "",
      baseUnitOptions: ["grams", "millilitres", "units"],
      unitName: "",
      baseAmount: "",
    }
  },
  methods: {
    submitHandle() {
      store.dispatch("updateUnits", {
        restaurantID: this.restaurantID,
        name: this.unitName,
        base: this.baseUnit,
        valInGrams: this.baseAmount,
      })
      this.newVal = ""
      this.base = ""
      this.valInGrams = ""
    },
  },
}
</script>
