<template>
  <v-list-item>
    <v-text-field
      id="field"
      v-model="newVal"
      :rules="[rules.required, rules.name]"
      label="Add New"
      append-icon="mdi-plus"
      @click:append="handleInput(newVal)"
    />
  </v-list-item>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      rules: {
        required: v => !!v || "Value is required",
        name: v => !"" || isNaN(v) || "Name value must be alphabetical",
      },
      newVal: "",
    }
  },
  methods: {
    handleInput(val) {
      this.$emit("input", val)
      this.newVal = ""
    },
  },
}
</script>
