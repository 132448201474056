<template>
  <form-dialog
    :visible="$route.params.form == 'ReportDialog' && itemToReport"
    :editMode="false"
    :title="title"
    :isCloseDisabled="false"
    :submitDisabled="false"
    :awaitingResponse="false"
    :showFormTitle="false"
    :showSubmit="false"
    @close="$emit('cancel')"
  >
    <v-row class="my-2">
      <custom-date-range-filter @change="dates = $event" />
    </v-row>
    <v-apex-charts type="area" :options="chartOptions" :series="formatGraphData" />
    <v-data-table :items="formatTableData" :headers="tableHeaders" class="mt-3">
      <template v-slot:[`item.costMoney`]="{ item }">
        {{ "$ " + item.costMoney }}
      </template>
      <template v-slot:[`item.total`]="{ item }">
        {{ "$ " + item.total }}
      </template>
      <template v-slot:[`footer.page-text`]>
        <vue-json-to-csv :json-data="formatTableData" :csv-title="title">
          <v-btn icon small><v-icon small>mdi-download</v-icon></v-btn>
        </vue-json-to-csv>
      </template>
    </v-data-table>

    <v-row class="mt-3">
      <v-col v-for="(menuItem, i) in menuItems" :key="i" cols="4">
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 4"
            outlined
            hover
            @click="goToMenuItem(menuItem)"
          >
            <v-img
              height="200px"
              :src="menuItem.itemImage.url ? menuItem.itemImage.url : noImageLink"
            />
            <v-card-title>
              <!-- {{ menuItem.name + " $" + getMenuItemCogs(menuItem.id) }} -->
              {{ menuItem.name }}
            </v-card-title>
            <v-card-subtitle>
              <!-- {{ generateSubtitle(menuItem) }} -->
              Subtitle will be calculated when cogs is being tracked
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from "./FormDialog.vue"
import { mapState } from "vuex"
import VueApexCharts from "vue-apexcharts"
import CustomDateRangeFilter from "../../shared/forms/datetime/CustomDateRangeFilter.vue"
import VueJsonToCsv from "vue-json-to-csv"

export default {
  name: "report-dialog",
  components: {
    FormDialog,
    "v-apex-charts": VueApexCharts,
    CustomDateRangeFilter,
    "vue-json-to-csv": VueJsonToCsv,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      receivings: [],
      xAxisDates: [],
      chartData: [],
      tableHeaders: [
        { text: "Date", value: "date" },
        { text: "Cost", value: "costMoney" },
        { text: "Quantity", value: "quantity" },
        { text: "Total", value: "total" },
      ],
      dates: null,
      noImageLink:
        "https://firebasestorage.googleapis.com/v0/b/nextgenkitchens-e9608.appspot.com/o/images%2Fdepositphotos_247872612-stock-illustration-no-image-available-icon-vector.webp?alt=media&token=a49a7c34-c9ff-494d-87b3-ee82aec9d143",
    }
  },
  computed: {
    ...mapState(["itemToReport", "items", "receivings"]),
    title() {
      return this.itemToReport
        ? "Cost Fluctuation Report for: " + this.itemToReport.name
        : ""
    },
    formatGraphData() {
      return [
        {
          data: this.chartData.map(
            item => parseFloat(item.quantity) * parseFloat(item.costMoney.amount)
          ),
          name: "Cost (CAD)",
        },
      ]
    },
    formatTableData() {
      let tableData = []
      this.xAxisDates.forEach((date, i) => {
        tableData.push({
          date: date,
          costMoney: this.chartData[i].costMoney.amount,
          quantity: this.chartData[i].quantity,
          total:
            parseFloat(this.chartData[i].costMoney.amount) *
            parseFloat(this.chartData[i].quantity),
        })
      })
      return tableData
    },
    chartOptions() {
      return {
        chart: {
          type: "area",
          toolbar: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "dd MMM yyyy",
            show: true,
          },
          tickAmount: this.xAxisDates.length,
          categories: this.xAxisDates,
        },
        markers: {
          size: 5,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy",
          },
        },
      }
    },
  },
  watch: {
    $route: {
      async handler(newVal) {
        if (
          !this.itemToReport?.hasOwnProperty("itemID") &&
          newVal.params.form == "ReportDialog"
        ) {
          this.$store.commit(
            "setItemToReport",
            this.items.find(item => item.itemID == newVal.params.id)
          )
        }
        if (newVal.params.form == "ReportDialog") {
          this.removeOtherItemsReceived()
        }
      },
    },
    dates: {
      handler(newVal) {
        if (newVal || this.dates.length > 0) {
          this.removeOtherItemsReceived()
          this.filterDataByDate(newVal)
        }
      },
    },
  },
  methods: {
    removeOtherItemsReceived() {
      this.chartData = []
      let filteredReceivedItems = {}
      this.xAxisDates = []
      this.receivings.forEach(receiving => {
        filteredReceivedItems = receiving.itemsReceived.filter(
          item => item.itemID == this.itemToReport.itemID
        )[0]
        if (filteredReceivedItems) {
          this.chartData.push({
            date: receiving.date,
            quantity: filteredReceivedItems.quantity,
            costMoney: filteredReceivedItems.costMoney,
            itemID: filteredReceivedItems.itemID,
          })
          this.xAxisDates.push(receiving.date)
        }
      })
      if (this.dates != null) {
        this.filterDataByDate(this.dates)
      }
    },
    filterDataByDate(dates) {
      for (let i = 0; i < this.chartData.length; i++) {
        if (
          new Date(this.chartData[i].date) <= new Date(dates[0]) ||
          new Date(this.chartData[i].date) >= new Date(dates[1])
        ) {
          this.chartData.splice(i, 1)
          this.xAxisDates.splice(i, 1)
          i--
        }
      }
    },
    getMenuItemCogs(menuItemId) {
      let menuItemIngredients = this.menuItems.find(
        menuItem => menuItem.id == menuItemId
      ).ingredients
      return menuItemIngredients
        .reduce((sum, obj) => {
          return sum + obj.cogs
        }, 0)
        .toFixed(2)
    },
    generateSubtitle(menuItem) {
      let ingredient = menuItem.ingredients.find(
        ingredient => ingredient.itemID == this.itemToReport.itemID
      )
      return (
        "This menu item requires " +
        ingredient.amount +
        " " +
        this.$store.getters.getUnitName(ingredient.unit) +
        "'s" +
        " of the selected Ingredient and the cost of this ingredient only is $" +
        ingredient.cogs
      )
    },
    goToMenuItem(menuItem) {
      this.$store.commit("setItemToReport", menuItem)
      this.$router.push({
        name: "Menu",
        params: { tab: "items", form: "ProfitabilityDialog", id: menuItem.id },
      })
    },
  },
}
</script>
