<template>
  <div>
    <form-dialog
      :visible="visible"
      :editMode="false"
      title="Link existing supplier item to an inventory item"
      :showFormTitle="false"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="$emit('close')"
      @submit="submitHandle"
    >
      <v-autocomplete
        v-model="invItemLinked"
        label="Link inventory item"
        :items="items"
        item-text="name"
        item-value="itemID"
        outlined
        dense
      />
    </form-dialog>
  </div>
</template>

<script>
import { updateDoc, doc } from "@firebase/firestore"
import { mapState } from "vuex"
import FormDialog from "../../shared/dialogs/FormDialog.vue"

export default {
  name: "existing-form",
  components: { FormDialog },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    supplierItemToLink: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      invItemLinked: "",
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "items"]),
  },
  watch: {
    visible(isVisible) {
      if (!isVisible) {
        this.clearFields()
      }
    },
  },
  methods: {
    async submitHandle() {
      this.awaitingResponse = this.closeDisabled = true
      try {
        await updateDoc(
          doc(this.firebaseRefs.supplieritemsRef, this.supplierItemToLink.id),
          { linkedItemID: this.invItemLinked }
        )
          .then(() => {
            this.$emit("close")
            this.clearFields()
          })
          .catch(error => {
            this.mainErrorMessage = "Error saving item: " + error.message
          })
        this.awaitingResponse = this.closeDisabled = false
      } catch (error) {
        this.mainErrorMessage = "Error saving item: " + error.message
        this.awaitingResponse = this.closeDisabled = false
      }
    },
    clearFields() {
      this.invItemLinked = ""
      this.mainErrorMessage = ""
    },
  },
}
</script>
