<template>
  <div>
    <form-dialog
      :visible="visible"
      :editMode="false"
      title="Link New Inventory Item"
      :showFormTitle="false"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      @close="$emit('close')"
      @submit="submitHandle"
    >
      <v-row class="mt-2">
        <v-col cols="4">
          <v-text-field v-model="newInvItem.itemID" label="Item ID" outlined />
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="newInvItem.name" label="Name" outlined />
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="newInvItem.threshold" label="Restock At" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="newInvItem.category"
            :items="categories"
            outlined
            label="Category"
          >
            <template v-slot:prepend-item>
              <new-option @input="updateCategories($event)" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="newInvItem.subcategory"
            :items="subcategories"
            outlined
            label="Subcategory"
          >
            <template v-slot:prepend-item>
              <new-option @input="updateSubcategories($event)" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="newInvItem.storage"
            :items="storages"
            item-text="name"
            item-value="id"
            outlined
            label="Storage"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3 class="my-5">Associated Supplier Item</h3>
          <p>
            {{ associatedSupplierItemName || "No supplier item linked" }}
          </p>
        </v-col>
      </v-row>
    </form-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import NewOption from "../../shared/forms/NewOption.vue"

export default {
  name: "new-form",
  components: {
    FormDialog,
    NewOption,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    supplierItemToLink: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      newInvItem: {
        itemID: "",
        name: this.supplierItemToLink?.name || "",
        threshold: 0,
        category: "General",
        subcategory: "General",
        storage: "",
      },
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState([
      "firebaseRefs",
      "categories",
      "subcategories",
      "storages",
      "supplierItems",
      "items",
    ]),
    associatedSupplierItemName() {
      return this.supplierItemToLink?.name || ""
    },
    nextItemID() {
      const sortedItems = [...this.items].sort((a, b) => {
        const itemID1 =
          typeof a.itemID === "number" ? a.item : parseFloat(a.itemID.split("-")[0])
        const itemID2 =
          typeof b.itemID === "number" ? b.item : parseFloat(b.itemID.split("-")[0])
        return itemID1 - itemID2
      })
      return parseFloat(sortedItems[0]?.itemID) + 1
    },
  },
  watch: {
    visible(isVisible) {
      if (!isVisible) {
        this.clearFields()
      }
    },
  },
  mounted() {
    this.newInvItem.itemID = this.nextItemID
  },
  methods: {
    ...mapActions([
      "updateCategories",
      "updateSubcategories",
      "fetchInventoryItems",
      "updateInventoryItems",
      "updateSupplierItems",
    ]),
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      await this.updateInventoryItems(this.newInvItem).catch(error => {
        console.error("Error saving item: ", error)
        this.mainErrorMessage = "Error saving inventory item: " + error.message
      })
      await this.updateSupplierItems({
        ...this.supplierItemToLink,
        linkedItemID: this.newInvItem.itemID,
      })
        .then(() => {
          this.$emit("close")
          this.clearFields()
        })
        .catch(error => {
          console.error("Error saving item: ", error)
          this.mainErrorMessage = "Error saving inventory item: " + error.message
        })
      this.awaitingResponse = this.closeDisabled = false
    },
    clearFields() {
      this.newInvItem = {}
      this.mainErrorMessage = ""
    },
  },
}
</script>
