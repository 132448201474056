<template>
  <v-app>
    <v-sheet class="mx-n4 mt-n4">
      <v-tabs v-model="tab" color="text" background-color="primary-darken4">
        <v-tab>Recipes & Ingredients</v-tab>
        <v-tab class="py-4">
          <v-badge
            v-if="unlinkedSupplierItems.length"
            color="error"
            :content="unlinkedSupplierItems.length"
          >
            Unlinked Inventory Items
          </v-badge>
          <div v-else>Unlinked Inventory Items (0)</div>
        </v-tab>
        <v-tab-item class="ma-4">
          <inventory-items />
        </v-tab-item>
        <v-tab-item class="ma-4">
          <unlinked-inventory-items
            :inventoryItems="items"
            :supplierItems="unlinkedSupplierItems"
          />
        </v-tab-item>
      </v-tabs>
    </v-sheet>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex"
import InventoryItems from "./Items/index.vue"
import UnlinkedInventoryItems from "./UnlinkedItems/index.vue"

export default {
  components: {
    InventoryItems,
    UnlinkedInventoryItems,
  },
  data() {
    return {
      tab: "linked",
      loadData: true,
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "supplierItems", "items", "menuItems"]),
    unlinkedSupplierItems() {
      return this.supplierItems.filter(item => item.linkedItemID == null)
    },
  },
  mounted() {
    this.fetchInventoryData()
  },
  methods: {
    ...mapActions(["fetchInventoryData"]),
  },
}
</script>
