<template>
  <form-dialog
    :visible="$route.params.form == 'AddEditForm'"
    :editMode="editMode"
    title="Inventory Item"
    :closeDisabled="formSubmitting"
    :submitDisabled="$v.$anyError"
    :awaitingResponse="formSubmitting"
    :error="mainErrorMessage"
    @close="$emit('cancel')"
    @submit="submitHandle"
  >
    <h3 class="my-5">Item Information</h3>
    <v-row class="3" justify="start">
      <v-col cols="3">
        <v-text-field
          v-model="itemID"
          :disabled="editMode"
          :title="editMode ? 'Item ID is not changeable' : ''"
          label="Item ID"
          :error-messages="getFormErrors($v, 'itemID')"
          outlined
          placeholder="Numeric ID"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="name"
          label="Name"
          :error-messages="getFormErrors($v, 'name')"
          outlined
          placeholder="Item Name"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="threshold"
          label="Restock At"
          :error-messages="getFormErrors($v, 'threshold')"
          outlined
          number
          placeholder="Minimum quantity"
        />
      </v-col>
      <v-col cols="4">
        <measurement-field
          v-model="measurement"
          allowUnitSelection
          label="Quantity on Hand"
        />
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col cols="4">
        <v-select
          v-model="category"
          :error-messages="getFormErrors($v, 'category')"
          :items="categories"
          outlined
          label="Category"
        >
          <template v-slot:prepend-item>
            <new-option @input="updateCategories($event)" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="subcategory"
          :items="subcategories"
          :error-messages="getFormErrors($v, 'subcategory')"
          outlined
          label="Subcategory"
        >
          <template v-slot:prepend-item>
            <new-option @input="updateSubcategories($event)" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="storage"
          :items="storages"
          item-text="name"
          item-value="id"
          :error-messages="getFormErrors($v, 'storage')"
          outlined
          label="Storage"
        />
      </v-col>
    </v-row>
    <div v-if="isRecipe">
      <h3 class="my-5">Recipe</h3>
      <ingredients v-model="recipeIngredients" :inventoryItems="items" />
      <h3 class="my-5">Recipe Yield</h3>
      <v-row justify="start">
        <v-col cols="4">
          <v-text-field v-model="yieldAmount" label="Yield" outlined />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="yieldUnit"
            :items="units"
            item-text="name"
            item-value="id"
            outlined
            label="Yield Unit"
          >
            <template v-slot:prepend-item>
              <new-unit :restaurantID="restaurantID" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="3">
          <h4>Total COGS: ${{ (recipeTotalCogs || 0).toFixed(2) }}</h4>
        </v-col>
      </v-row>
    </div>
    <div>
      <h3 class="my-5">Associated Supplier Item</h3>
      <p>{{ getAssociatedSupplierItemName(itemID) || "No supplier item linked" }}</p>
    </div>
  </form-dialog>
</template>

<script>
import NewOption from "../../shared/forms/NewOption.vue"
import NewUnit from "../../shared/forms/NewUnit.vue"
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import MeasurementField from "../../shared/forms/MeasurementField.vue"
import { mapActions, mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import Ingredients from "../../shared/forms/Ingredients.vue"
import topLevelMixins from "../../mixins.js"

export default {
  name: "add-edit-form",
  components: {
    NewOption,
    NewUnit,
    FormDialog,
    Ingredients,
    MeasurementField,
  },
  mixins: [topLevelMixins],
  data() {
    return {
      itemID: "",
      name: "",
      threshold: "",
      restaurantID: "",
      measurement: this.createEmptyMeasurementObject(),
      category: "",
      subcategory: "",
      storage: "",
      recipeIngredients: [],
      recipeTotalCogs: 0,
      yieldAmount: null,
      yieldUnit: null,
      formSubmitting: false,
      mainErrorMessage: "",
    }
  },
  validations() {
    return {
      name: { required },
      threshold: { required, numeric },
      category: { required },
      subcategory: { required },
      storage: { required },
    }
  },
  computed: {
    ...mapState([
      "categories",
      "subcategories",
      "storages",
      "items",
      "supplierItems",
      "units",
      "firebaseRefs",
      "itemToEdit",
    ]),
    editMode() {
      return this.itemToEdit != null
    },
    isRecipe() {
      return this.$route.query.type === "recipe"
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm" && this.editMode) {
          this.prepEdit()
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
    recipeIngredients: {
      handler() {
        this.calculateRecipeTotalCogs()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      "updateCategories",
      "updateSubcategories",
      "updateInventoryItems",
    ]),
    getAssociatedSupplierItem(itemID) {
      if (!itemID) return
      return this.supplierItems.find(
        supplierItem => supplierItem.linkedItemID === itemID
      )
    },
    getAssociatedSupplierItemName(itemID) {
      const supplierItem = this.getAssociatedSupplierItem(itemID)
      return supplierItem ? supplierItem.name : ""
    },
    async calculateRecipeTotalCogs() {
      var sum = 0
      for (var ingredient of this.recipeIngredients) {
        const supplierItem = this.getAssociatedSupplierItem(ingredient.itemID)
        const unitCost = this.getUnitCostFromCostMoney(
          supplierItem.costMoney,
          ingredient.measurement.unit
        )
        sum += parseFloat(unitCost) * parseFloat(ingredient.measurement.quantity)
      }
      this.recipeTotalCogs = sum
    },
    async submitHandle() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.formSubmitting = true
        if (this.itemID != "" && this.name != "") {
          let newItem = {
            itemID: this.itemID,
            name: this.name,
            isRecipe: !!this.isRecipe,
            threshold: this.threshold,
            category: this.category,
            subcategory: this.subcategory,
            storage: this.storage,
            measurement: this.measurement,
          }
          if (this.isRecipe) {
            newItem.recipeIngredients = this.recipeIngredients
            newItem.yieldAmount = this.yieldAmount
            newItem.yieldUnit = this.yieldUnit
            newItem.price = this.recipeTotalCogs
          }
          if (!this.editMode) {
            newItem.quantity = this.isRecipe ? parseFloat(this.yieldAmount) : 0
          }
          this.updateInventoryItems(newItem)
            .then(() => {
              this.$router.push({ params: { form: null, id: null }, query: {} })
              this.clearFields()
            })
            .catch(error => {
              console.error(error)
              this.mainErrorMessage = "Error saving inventory item: " + error.message
            })
            .finally(() => {
              this.formSubmitting = false
            })
        }
        this.$emit("newItem")
      }
    },
    prepEdit() {
      this.itemID = this.itemToEdit.itemID
      this.name = this.itemToEdit.name
      this.threshold = this.itemToEdit.threshold
      this.category = this.itemToEdit.category
      this.subcategory = this.itemToEdit.subcategory
      this.storage = this.itemToEdit.storage
      this.recipeIngredients = this.itemToEdit.recipeIngredients || []
      this.yieldAmount = this.itemToEdit.yieldAmount
      this.yieldUnit = this.itemToEdit.yieldUnit
      this.$route.query.type = this.itemToEdit.isRecipe ? "recipe" : "ingredient"
      this.measurement =
        this.itemToEdit.measurement || this.createEmptyMeasurementObject()
      this.calculateRecipeTotalCogs()
    },
    clearFields() {
      this.$v.$reset()
      this.itemID =
        this.name =
        this.restaurantID =
        this.threshold =
        this.category =
        this.subcategory =
        this.storage =
        this.yieldAmount =
        this.yieldUnit =
        this.mainErrorMessage =
          ""
      this.measurement = this.createEmptyMeasurementObject()
      this.recipeIngredients = []
      this.formSubmitting = false
      this.$emit("cancel")
    },
  },
}
</script>
